<template>

    <div>

        <Breadcrumb titulo="Clientes"
                    :items="itemsBreadcrumb"/>

        <div class="mainContainer">

            <!--CONTAINER PRINCIPAL============================================================-->
            <div class="internalContainer">


                <b-row class="funcoesTable">

                    <!--CAMPOS DE PESQUISA ------------------------------------------->
                    <b-col class="textBox-container" cols="8">
                        <input type="text"
                            v-model="searchNome"
                            style="margin-right: 10px;"
                            placeholder="Nome Cliente" />

                        <input type="text"
                            v-model="searchCPF"
                            style="margin-right: 10px;"
                            placeholder="CPF" />

                        <input type="text"
                            v-model="searchTelefone"
                            style="margin-right: 10px;"
                            placeholder="Telefone" />

                        <input type="text"
                            v-model="searchEmail"
                            style="margin-right: 10px;"
                            placeholder="E-mail" />

                        <input type="text"
                            v-model="searchUf"
                            style="margin-right: 10px; width: 80px;"
                            placeholder="UF" />

                        <b-button class="plus-button fa fa-plus"
                                @click="filtrosCliente">
                        </b-button>

                        <b-button class="fas fa-search search-button"
                                @click="buscarClientes(0)">
                        </b-button>
                    </b-col>


                    <!--BOTÕES DE AÇÃO ------------------------------------------->
                    <b-col class="botoes-acao-container" cols="4" style="padding-right: 0;">

                        <b-button id="configButton"
                                class="font-button rounded-0"
                                @click="criarCampo">
                            Campos Personalizados
                        </b-button>

                        <b-button id="configButton"
                                class="font-button rounded-0"
                                @click="importarClientes"
                                style="margin-left:  1%">Importar dados</b-button>

                        <b-button id="configButton"
                                class="font-button rounded-0"
                                @click=" exportarListaClientes()"
                                style="margin-left: 1%">
                            Exportar dados
                        </b-button>

                        <b-button id="configButton"
                                class="font-button rounded-0"
                                @click="novoCliente"
                                style="margin-left: 1%">Novo cliente</b-button>
                    </b-col>

                </b-row>

                <!--TABELA DE CLIENTES ------------------------------------------->
                <div class="tableContainer">

                    <b-container fluid class="h-100" 
                                 v-if="carregandoClientes">
                        <b-row align-v="center" 
                               align-h-="center" 
                               class="h-100">
                            <b-col>
                                <center>
                                    <b-spinner variant="secondary"
                                               class="mr-1"
                                               large>
                                    </b-spinner>
                                </center>
                            </b-col>
                        </b-row>
                    </b-container>

                    <b-table sticky-header
                             :no-border-collapse="noCollapse"
                             id="tabelaClientes"
                             striped
                             :items="clientes"
                             :fields="fieldsVisiveis"
                             class="clientesTable"
                             v-if="!carregandoClientes"
                             style="font-size:13px">
                        <template #head(more)>

                            <i :class="`fas ${verMaisCampos && fields.length > 0 ? 'fa-times' : 'fa-plus'} fa-fw float-right mr-3 cursor-pointer cliente-mais-campos-opener`"
                               title="Mais campos..."
                               @click="verMaisCampos = !verMaisCampos"
                               v-if="!fields.length == 0"></i>
                            <ul v-if="verMaisCampos && fields.length > 0"
                                :class="`app-scroll-custom${loaders.invertendoVisibilidadeDoCampo ? ' loading' : ''}`">
                                <li v-for="campo in fields"
                                    style="cursor: pointer; user-select: none;"
                                    @click="inverterVisibilidadeCampo(campo)"
                                    class="text-truncate"
                                    :title="campo.label ?? campo.NomeCampo">
                                    <i v-if="loaders.invertendoVisibilidadeDoCampo != campo.Id"
                                       :class="`fas ${campo.Listado ? 'fa-check' : ''} mr-1 fa-fw`"></i>
                                    <i v-else-if="campo.Id" class="fas fa-sync-alt fa-spin mr-1 fa-fw text-primary"></i>
                                    {{campo.NomeCampo ?? campo.label}}
                                </li>
                            </ul>
                        </template>
                        <template #cell(more)="row">

                            <div class="botoes-edicao  my-1 mr-1 float-right">
                                <i class="fas fa-trash-alt mr-3 float-right"
                                   @click="excluirCliente(row.item.Id)" style="cursor:pointer">
                                </i>

                                <i class="fas fa-pen mr-3 float-right"
                                   @click="editCliente(row.item)"
                                   style="cursor:pointer">

                                </i>
                            </div>
                        </template>
                    </b-table>

                </div>
                <b-row id="footerTabela">
                    <b-col class="mt-2" cols="3"><b>Total: </b> {{ totalRows }}</b-col>
                    <!--PAGINAÇÃO ==============================================-->
                    <b-col id="componentePaginacao" 
                        class="mt-2">

                        <button class="fas fa-angle-left"
                                id="retrocederPag"
                                @click="retrocederPage"
                                :disabled="disableRetrocederPage || carregandoClientes">
                        </button>


                        <span class="infoPaginacao">{{currentPage+1}} / {{pageCount == 0 ? 1 : pageCount}}</span>

                        <button class="fas fa-angle-right" id="avancarPag"
                                @click="avancarPage"
                                :disabled="disableAvancarPage || carregandoClientes">
                        </button>

                    </b-col>

                    <camposPersonalizadosModal ref="campos-Personalizados" @camposAdicionados="adicionarColunas" />

                    <novoClienteModal ref="novo-ClienteModal" />
                    <exportarListaClientes ref="exportar-ListaClientes" :campos="fieldsExportacao" />
                    <importarDados ref="Importar-Dados"/>
                    <b-col cols="3" class="mt-2 mr-2 d-flex justify-content-end">
                        <span v-if="statusImportacao == 'EmAndamento'">Importando {{ andamentoImportacao.total - andamentoImportacao.restantes }} de {{ andamentoImportacao.total }} ({{ andamentoImportacao.porcentagem }}) </span>
                        <span v-else-if="statusImportacao == 'Concluida'">
                            <i class="fa fa-check-circle-o"></i><b>  Importação concluída</b>
                        </span>
                    </b-col>
                </b-row>
            </div>

        </div>

        <ModalFiltrosClientes ref="Campanha-Filtros" />
    </div>

</template>

<!---->

<script>

    import {ref, nextTick} from 'vue'
    import axios from 'axios'
    import novoClienteModal from '@/components/clienteNewPage/novoClienteModal.vue'
    import SDHubIndicadores from '@/assets/js/hubs/RegistrosHub'
    import camposPersonalizadosModal from '@/components/clienteNewPage/camposPersonalizadosModal.vue'
    import ModalFiltrosClientes from "@/components/filtros-cliente/ModalFiltroClientes.vue";
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import exportarListaClientes from '@/components/clienteNewPage/exportarModal.vue'
    import importarDados from '@/components/clienteNewPage/importarModal.vue'

    export default {

        components: {

            Breadcrumb,
            camposPersonalizadosModal,
            SDHubIndicadores,
            novoClienteModal,
            ModalFiltrosClientes,
            importarDados,
            exportarListaClientes,
        },

        data() {

            return {

                itemsBreadcrumb: [
                    {
                        id: '2',
                        classe: 'fa fa-cogs',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos'
                    },

                    {
                        id: '3',
                        classe: 'fas fa-users',
                        texto: 'Clientes',
                        link: '/clientes'
                    }
                ],

                fields: [],
                camposPadrao: [
                    { key: 'Nome', label: 'Nome',Listado: true },
                    { key: 'CnpjCpf', label: 'CPF/CNPJ' ,Listado: true},
                    { key: 'Telefone', label: 'Telefone',Listado: true },
                    { key: 'Email', label: 'E-mail',Listado: true },
                    { key: 'Endereco', label: 'Endereco',Listado: false },
                    { key: 'Municipio', label: 'Cidade',Listado: false },
                    { key: 'UF', label: 'Estado',Listado: true }
                ],
            
                clientes: [],

                carregandoClientes: true,

                perPage: 10,
                currentPage: 0,
                disabled: false,
                noCollapse: true,
                totalRows: null,
                pageCount: null,
                retornoFiltros: [],
                filtroPesquisa: '',
                textoPesquisa: '',
                listaFiltros: {},
                dadosCliente: '',
                verMaisCampos: false,
                buscando: false,
                searchNome: null,
                searchCPF: null,
                andamentoImportacao: {
                    porcentagem: '',
                    total: 0,
                    restantes: 0
                },
                statusImportacao: 'NaoIniciada',
                searchTelefone: null,
                searchEmail: null,
                searchUf: null,
                loaders: {
                    consultandoCamposDosClientes: false,
                    consultandoDadosDePesquisa: false,
                    invertendoVisibilidadeDoCampo: null
                }
            }
        },
        destroyed() {
            this.statusImportacao = 'NaoIniciada';
			SDHubIndicadores.stop();
		},
        computed: {
            fieldsVisiveis() {
                let fields = [...this.camposPadrao.filter(campo => campo.Listado).map(campo => ({
                    label: campo.label,
                    key: campo.key
                })), ...(this.fields.filter(campo => campo.Listado && (campo.Id || campo.key)).map(campo => ({
                    label: campo.NomeCampo ?? campo.label,
                    key: campo.Id ?? campo.NomeCampo ?? campo.key
                })) ?? [])];
                fields.push({ key: 'Inclusao', label: 'Data de Cadastro',Listado: true })
                fields.push("more");
                return fields;
            },
            fieldsExportacao(){
                let fields = [...this.camposPadrao.map(campo => ({
                    label: campo.label,
                    key: campo.key
                })), ...(this.fields.filter(campo => campo.Id || campo.key).map(campo => ({
                    label: campo.NomeCampo ?? campo.label,
                    key: campo.Id ?? campo.NomeCampo ?? campo.key
                })) ?? [])];
                return fields;
            },
            rows() {
                return this.totalRows
            },

            disableRetrocederPage(){
                if (this.currentPage <= 0){
                    return true
                }
            },

            disableAvancarPage(){
                if (this.currentPage >= this.pageCount -1){
                    return true
                }

            },
            
        },

    created(){
            this.carregarClientes();
            this.carregarHubIndicadores();
        },


        methods: {
            carregarHubIndicadores(){
                SDHubIndicadores.onClienteAdicionado(this.importacaoClientes);
                SDHubIndicadores.onImportacaoFinalizada(this.finalizaImportacao);
                SDHubIndicadores.onImportacaoNaoIniciada(this.ImportacaoNaoIniciada);
                SDHubIndicadores.start().then(() => {
                    SDHubIndicadores.comecarOuvirImportacaoCliente();
                });
            },
            filtrosCliente(){
                this.$refs["Campanha-Filtros"].iniciarModal("clientePage",this.retornoFiltros ? this.retornoFiltros : []).then(resolve => {
                    this.retornoFiltros = resolve.campos;
                    this.listaFiltros = resolve.query ?? {};

                    this.buscarClientes(0);
                })
            },
            carregarClientes() {
                this.carregandoClientes = true;
                axios.get('api/v2/clientes/BuscarConfiguracoes').then(res => {
                    this.fields = [];
                    res.data.forEach(comp => {
                        this.fields.push(comp)
                    });
                });
                axios.get('api/v2/clientes/PaginarClientes', {
                    params:{
                        pageNumber: this.currentPage,
                        pageQuantity: this.perPage,
                    }
                }).then(res =>{
                    this.totalRows = res.data.TotalItems;
                    
                    let page_count = Math.ceil(this.totalRows / this.perPage);

                    this.pageCount = page_count;

                    this.clientes = res.data.listClienteDTO;
                    this.clientes.map(item => Object.assign(item, ...item.Complementos.map(comp => ({ [comp.ClientesConfigComplementoId]: comp.Resposta }))))
                    this.carregandoClientes = false;
                })


            },
            buscarClientes(pagina){
                this.currentPage = pagina;
                this.carregandoClientes = true;
                if(this.searchNome != null) this.listaFiltros["Nome"] = this.searchNome;
                if(this.searchCPF != null) this.listaFiltros["Cnpj/Cpf"] = this.searchCPF;
                if(this.searchTelefone != null) this.listaFiltros["Telefone"] = this.searchTelefone;
                if (this.searchEmail != null) this.listaFiltros["Email"] = this.searchEmail;
                if (this.searchUf != null) this.listaFiltros["UF"] = this.searchUf;

                let listaQuery = JSON.stringify(this.listaFiltros);

                axios.get(`api/v2/clientes/busca-filtros`,{
                        params:{
                            clientesParaBuscar:listaQuery,
                            pageNumber: this.currentPage,
                            pageQuantity: this.perPage
                        }})
                        .then(res => {
                            this.totalRows = res.data.TotalItems;
                    
                            let page_count = Math.ceil(this.totalRows / this.perPage);

                            this.pageCount = page_count;

                            this.clientes = res.data.listClienteDTO;
                            this.clientes.map(item => Object.assign(item, ...item.Complementos.map(comp => ({ [comp.ClientesConfigComplementoId]: comp.Resposta }))))
                            this.carregandoClientes = false;

                    }).catch(erro => {
                        console.error("Não foi possível carregar clientes.", erro);
                    });
            },
            novoCliente() {
                this.$refs["novo-ClienteModal"].iniciarModal().then(() => {
                    this.buscarClientes(0);
                })
            },
            editCliente(cliente){
                this.$refs["novo-ClienteModal"].iniciarModal(cliente).then(() => {
                    this.buscarClientes(0);
                })
            },
            criarCampo() {
                this.$refs["campos-Personalizados"].iniciarModal().then(() => {
                    this.buscarClientes(0);
                    this.$refs["campos-Personalizados"].fecharFormulario();
                });
            },

            excluirCliente(Id) {
                this.$bvModal.msgBoxConfirm("Deseja excluir o cliente?", {
                    title: 'Atenção',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    okVariant: 'success',
                    cancelVariant: 'white',
                    centered: true
                }).then(confirmado => {
                    if(confirmado){
                        axios.delete(`api/v2/clientes/`+Id).catch(error => {
                            console.error(error);
                        }).then(() =>{
                            this.carregarClientes();
                        })
                    }
                })
            },

            exportarListaClientes(){
                if(this.searchNome != null) this.listaFiltros["Nome"] = this.searchNome;
                if(this.searchCPF != null) this.listaFiltros["Cnpj/Cpf"] = this.searchCPF;
                if(this.searchTelefone != null) this.listaFiltros["Telefone"] = this.searchTelefone;
                if (this.searchEmail != null) this.listaFiltros["Email"] = this.searchEmail;
                if (this.searchUf != null) this.listaFiltros["UF"] = this.searchUf;

                let listaQuery = JSON.stringify(this.listaFiltros);

                let dados = {
                    Filtros: listaQuery,
                    pageNumber: 0,
                    pageQuantity: 100000
                }

                this.$refs["exportar-ListaClientes"].iniciarModal(dados);
            },

            importarClientes() {
                this.$refs["Importar-Dados"].iniciarModal().then(() => {
                    this.carregarClientes();
                    this.carregarHubIndicadores();
                });
            },

            adicionarColunas(campos) {
                campos.forEach(campo => {
                    this.fields.push({ key: campo.key, label: campo.texto })
                })
            },

            avancarPage(){
                this.currentPage+=1
            },            
            
            retrocederPage(){
                this.currentPage-=1
            },            
            
            notificarCliente() {
                this.$bvToast.toast(`Houve um erro ao tentar salvar o anexo .`, {
                    title: "Ops, algo deu errado...",
                    toaster: "b-toaster-top-right",
                    variant: "danger",
                    solid: true,
                    autoHideDelay: 5000
                });
            },
           
            inverterVisibilidadeCampo(campo) {
                if (this.loaders.invertendoVisibilidadeDoCampo) return;
                this.loaders.invertendoVisibilidadeDoCampo = campo.Id;
                campo.Listado = !campo.Listado;
                if(campo.Id){
                    axios.put(`api/v2/clientes/campos/${campo.Id}/${campo.Listado ? "destacar" : "ocultar"}-campo-no-cliente`).then(() => {
                        this.loaders.invertendoVisibilidadeDoCampo = null;
                        if (campo.EmDestaqueNaTabela) this.carregarClientes();
                    }).catch(() => {
                        this.loaders.invertendoVisibilidadeDoCampo = null;
                    });
                }
            },
            importacaoClientes(total, restante){
                this.statusImportacao = 'EmAndamento';
                this.andamentoImportacao.total = total;
                this.andamentoImportacao.restantes = restante;
                this.andamentoImportacao.porcentagem = (((total - restante) / total) * 100).toFixed(2) + "%"
            },
            finalizaImportacao(){
                SDHubIndicadores.stop();
                this.statusImportacao = 'Concluida';
            },
            ImportacaoNaoIniciada(){
                SDHubIndicadores.stop();
                this.statusImportacao = 'NaoIniciada';
            }
        },
        watch:{
            currentPage(){
                this.buscarClientes(this.currentPage);
            }
        }

    }


</script>

<!---->

<style scoped>
    #footerTabela {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 15px;
    }
    .plus-button{
        background-color: #F8F8F8;
        color: #AEAEAE;
        font-size:14px;
        height: 4.8vh;
        margin-right: 10px;
        border-radius: 0;
        border:0px solid;
        max-height: 35px;
    }
    .mainContainer {
        padding: 10px;
        display:block;
        overflow-x: auto;
        color: #757575;
    }

    .internalContainer {
        padding: 10px;
        padding-bottom: 0;
        height: calc(100vh - 135px);
        width: calc(100vw - 84px);
        min-width: 1104px;
        background-color: var(--cinza-3);
    }

    .funcoesTable {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 15px;
    }

    .textBox-container{
        padding:0;
    }

        .textBox-container > input[type=text] {
            width: 120px;
        }

    .botoes-acao-container{
        text-align:end;
    }

    .font-button{
        background-color: #14396B;
        font-size:13px;
    }
    .search-button{
        background-color: #14396B;
        font-size:14px;
        height: 4.8vh;
        margin-right: 10px;
        border-radius: 0;
        border:0px solid;
        max-height: 35px;
    }
    input[type=text] {
        border: none;
        background-color: #ffffff;
        opacity: 0.7;
        width: 20%;
    }

    input[type=text]:focus {
        opacity: 1;
        background-color: #ffffff;
        border-bottom: 0.5px #14396B solid;
    }


    .IconSearch {
        background-color: #14396B;
        color: white;
        padding-top: 1%;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 9px;
        padding-bottom: 10px;
        border-radius:0;
        font-size:15px;
        border: 0;
        margin-right: 10px;
    }

    #configButton {
        width:auto;
        border: none;
        padding: 1%;
        padding-left: 2%;
        padding-right: 2%;
    }

    .tableContainer {
        color: #707070;
        margin: 2% 0 0;
        background-color: #FDFDFD;
        border: none;
        height: 86%;
        width:100%
    }

    .clientesTable {
        color: #707070;
        height: 100%;
        width: 100%;
        text-align:initial;
        max-height: none;
    }

    .botoes-edicao {
        width: 57px;
        font-size:13px;
        text-align:center;
    }

    #componentePaginacao {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content:center;
        font-size: 15px;
        color: #707070;
        user-select: none;
        width: 100%;
        height:6%;
    }

    #retrocederPag {
        color: #707070;
        padding: 0;
        margin-right: 1%;
        border:none;
        background:none;
    }    
    #retrocederPag:disabled {
        opacity: 0.5;
    }

    #avancarPag {
        color: #707070;
        padding: 0;
        margin-left: 1%;
        border: none;
        background: none;
    }

    #avancarPag:disabled {
        opacity: 0.5;
    }

    .infoPaginacao {
        margin-top: 2px;
        font-size: 15px;
        font-weight: bold;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
        height:5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background-color: #E8E8E8;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #707070;
        border-radius: 10px;
    }

    .cliente-mais-campos-opener {
        color: #212529;
        width: 20px;
        height: 20px;
        padding: 4px;
    }

        .cliente-mais-campos-opener.fa-times {
            background-color: #212529;
            color: #fff;
            border-radius: 50%;
        }

        .cliente-mais-campos-opener + ul {
            position: absolute;
            background-color: #fff;
            list-style-type: none;
            padding: 0;
            max-height: 50vh;
            overflow-y: auto;
            box-shadow: 0 0 2px rgba(0 0 0 / .5);
            right: 20px;
            margin-top: 27px;
            max-width: 300px;
        }

            .cliente-mais-campos-opener + ul.loading {
                color: var(--cinza-4);
            }

            .cliente-mais-campos-opener + ul > li {
                padding: 5px 10px 5px 5px;
                border-bottom: 1px var(--cinza-3) solid;
                white-space: nowrap;
                background-color: #fff;
                transition: all ease-in-out .3s;
            }

                .cliente-mais-campos-opener + ul > li:hover {
                    background-color: var(--cinza-3);
                }

            .cliente-mais-campos-opener + ul.loading > li:hover {
                background-color: #fff;
            }
</style>