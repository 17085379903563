<template>
    <b-modal id="exportar-ListaClientes" 
             ref="exportarListaClientes" 
             centered title="Exportar lista de Clientes" 
             class="titleModal" 
             size="md" 
             scrollable 
             @hide="limpaModal()">
        <b-container>

            <b-col class="data-field">
                <label class="titleSetores">Selecione as colunas desejadas:</label>


                <div class="opcoesCheckbox">
                    <div v-for="option in options">
                        <input type="checkbox" 
                               :id="`${option.CampoId ?? option.LabelCampo}`" 
                               :value="option.LabelCampo" 
                               v-model="option.isChecked">

                        <label :for="`${option.CampoId ?? option.LabelCampo}`"><i :class="`${option.isChecked ? 'fas' : 'far'} fa-check-square`"></i> {{option.LabelCampo}}</label>
                    </div>
                </div>       

            </b-col>

        </b-container>

        <!--BOTOES FOOTER MODAL =============================-->
        <template #modal-footer="{ cancel }">

            <b-button variant="light" 
                      @click="cancel()" 
                      class="rounded-0">Cancelar</b-button>

            <b-button variant="success" 
                      @click="exportar"
                      :disabled="isSending"
                      class="rounded-0">
                <span v-if="isSending">
                    <b-spinner small class="mr-1"></b-spinner>
                    Exportando...
                </span>
                <span v-else>
                    Exportar
                </span>
            </b-button>

        </template>

    </b-modal>

</template>

<!--=====================================================================================-->

<script>
    import axios from 'axios';
    export default{

        name: 'exportarListaClientes',

        data(){

            return {
                options: [
                ],
                filtros: [],
                isSending: false
            };
        },
        props:{
            campos:{
                fields:[]
            }
        },
        methods: {

            iniciarModal(filtros) {
                this.filtros = filtros
                this.filtros.pageQuantity = 100000;
                this.filtros.pageNumber = 0;
                this.$bvModal.show("exportar-ListaClientes");
                this.campos.forEach(campo =>{
                    var novoCampo = {
                        LabelCampo : campo.label,
                        NomeCampo : campo.key,
                        CampoId: this.options.length > 6 ? campo.key : null,
                        isChecked : true
                    }
                    this.options.push(novoCampo)
                })
                this.options.push({LabelCampo : "Data de cadastro",
                        NomeCampo : "Inclusao",
                        CampoId: null,
                        isChecked : true})
            },
            exportar() {
                this.isSending = true;
                let dados = {
                    ColunasExportarCliente: this.options,
                    Filtros: this.filtros.Filtros,
                    pageNumber: this.filtros.pageNumber,
                    pageQuantity: this.filtros.pageQuantity
                }
                axios.post(`api/v2/clientes/ExportarConsultaCliente`, dados, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then(res => {
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(new Blob([res.data]))
                    link.download = `listaClientes_${Date.now()}.xlsx`
                    link.click();
                    link.remove();
                    this.isSending = false;
                    this.limpaModal();
                    this.$bvModal.hide("exportar-ListaClientes");
                }).catch(err => {
                    console.log(err);
                })
            },
            limpaModal(){
                this.options = []
            }

        }

    }

</script>

<!--=====================================================================================-->

<style scoped>

    #exportar-ListaClientes input[type=text] {
        opacity: 1;
        background-color: #F5F2F2;
        border: 1px #737373 solid;
        min-width: 100%;
    }

    #exportar-ListaClientes input[type=text]:focus {
        background-color: #F5F2F2;
    }

    .data-field {
        color: #737373;
    }

    #exportar-ListaClientes input[type=checkbox] {
        display:none;
    }

    .opcoesCheckbox label {
        font-size: 12px;
        cursor: pointer;
        padding: 5px;
        width: 100%;
        opacity: 0.5;
        background-color: #F5F2F2;
        margin: 0;
    }

    .opcoesCheckbox {
        border: 1px #737373 solid;
    }

    #exportar-ListaClientes input[type=checkbox]:hover + label {
        opacity: 1;
    }

    #exportar-ListaClientes input[type=checkbox]:checked + label {
        background-color: #E8E8E8;
        opacity: 1;
    }
 
</style>